import gitExample from './assets/mock/gitExample.jpg';

const ProjectsData = [
    {
        "id": 1,
        "img": gitExample,
        "title": "Nome do projeto",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
        "tool": "Tool1 · Tool2",
        "github": "https://github.com/Rdemora2",
        "link": ""
    },
    {
        "id": 2,
        "img": gitExample,
        "title": "Nome do projeto",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
        "tool": "Tool1 · Tool2",
        "github": "https://github.com/Rdemora2",
        "link": ""
    },
    {
        "id": 3,
        "img": gitExample,
        "title": "Nome do projeto",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
        "tool": "Tool1 · Tool2",
        "github": "https://github.com/Rdemora2",
        "link": ""
    },
    {
        "id": 4,
        "img": gitExample,
        "title": "Nome do projeto",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
        "tool": "Tool1 · Tool2",
        "github": "https://github.com/Rdemora2",
        "link": ""
    }
]

export default ProjectsData;